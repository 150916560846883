module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hues","short_name":"hues","start_url":"/","icon":"src/images/logo-512x512.png","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hues","short_name":"hues","start_url":"/","icon":"src/images/logo-512x512.png","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone"},
    }]
